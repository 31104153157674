import { RumInitConfiguration } from '@datadog/browser-rum';

export type PortalEnv = 'local' | 'development' | 'demo' | 'production';

// These stay the same across environments
const sharedConfig = {
    applicationId: '2b283e09-35ed-44ce-b307-ad8bb5712199',
    clientToken: 'pub436814d9aa913730cce81a7c5f61c15c',
    site: 'us5.datadoghq.com',
    service: 'nextjs-portal',
    env: process.env.NEXT_PUBLIC_RX_ENV,
};

export const DATADOG_CONFIG_MAP: Record<PortalEnv, RumInitConfiguration> = {
    local: {
        ...sharedConfig,
        sessionSampleRate: 0,
        sessionReplaySampleRate: 0,
        trackUserInteractions: false,
        trackResources: false,
        trackLongTasks: false,
        defaultPrivacyLevel: 'allow',
    },
    development: {
        ...sharedConfig,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 0,
        trackUserInteractions: true,
        trackResources: false,
        trackLongTasks: false,
        defaultPrivacyLevel: 'allow',
    },
    demo: {
        ...sharedConfig,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackResources: false,
        trackLongTasks: true,
        defaultPrivacyLevel: 'allow',
    },
    production: {
        ...sharedConfig,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
    },
};
