import { useEffect, useState } from 'react';

// This hook is used to get the base URL of the current page
// Includes the protocol, domain, and port (if applicable)
export function useBaseUrl() {
    const [baseUrl, setBaseUrl] = useState<string>('');

    useEffect(() => {
        setBaseUrl(window.location.origin);
    }, []);

    return { baseUrl };
}
