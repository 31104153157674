import { useQueryParams } from '@/hooks/shared/useQueryParams';
import { useSidenavOpenState } from '@/hooks/shared/useSidenavOpenState';
import { Kbd, Stack, Text } from '@mantine/core';
import { useHotkeys } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { ReactNode } from 'react';

interface KeyboardShortcutProviderProps {
    children: ReactNode;
}

export default function KeyboardShortcutProvider({ children }: KeyboardShortcutProviderProps) {
    const { practiceUuid, prescriptionUuid, practiceId, corpGroupId } = useQueryParams();

    const [_, setDesktopOpened] = useSidenavOpenState();

    // Define which keyboard shortcuts to support, and their handlers
    useHotkeys([
        ['mod+alt+H', handleHelp],
        ['mod+alt+R', handleCopyRxUuid],
        ['mod+alt+P', handleCopyPracticeUuid],
        ['mod+alt+E', handleCopyEntityId],
        ['mod+B', handleToggleDesktopSidenav],
    ]);

    // Show a toast explaining the available keyboard shortcuts
    function handleHelp() {
        notifications.show({
            title: 'Available keyboard shortcuts',
            message: (
                <Stack gap={0} my="md">
                    <Text size="sm">
                        <Kbd>mod</Kbd> + <Kbd>alt</Kbd> + <Kbd>H</Kbd> - Show this help dialog
                    </Text>
                    <Text size="sm">
                        <Kbd>mod</Kbd> + <Kbd>alt</Kbd> + <Kbd>R</Kbd> - Copy Rx UUID to clipboard
                    </Text>
                    <Text size="sm">
                        <Kbd>mod</Kbd> + <Kbd>alt</Kbd> + <Kbd>P</Kbd> - Copy practice UUID to
                        clipboard
                    </Text>
                    <Text size="sm">
                        <Kbd>mod</Kbd> + <Kbd>alt</Kbd> + <Kbd>E</Kbd> - Copy entity ID to clipboard
                    </Text>
                    <Text size="sm">
                        <Kbd>mod</Kbd> + <Kbd>B</Kbd> - Toggle desktop left nav
                    </Text>
                </Stack>
            ),
            withCloseButton: true,
            autoClose: false,
            color: 'gray',
            withBorder: true,
        });
    }

    // Copy Rx UUID from URL to clipboard
    async function handleCopyRxUuid() {
        if (!prescriptionUuid) {
            showWarning('Failed to copy Rx UUID - no Rx UUID found in URL');
            return;
        }

        try {
            await navigator.clipboard.writeText(prescriptionUuid);

            showSuccessMessage('Copied Rx UUID to clipboard');
        } catch (e) {
            showWarning(`Failed to copy Rx UUID to clipboard: ${e}`);
        }
    }

    // Copy practice UUID from URL to clipboard
    async function handleCopyPracticeUuid() {
        if (!practiceUuid) {
            showWarning('Failed to copy practice UUID - no practice UUID found in URL');
            return;
        }

        try {
            await navigator.clipboard.writeText(practiceUuid);

            showSuccessMessage('Copied practice UUID to clipboard');
        } catch (e) {
            showWarning(`Failed to copy practice UUID to clipboard: ${e}`);
        }
    }

    // Copy entity ID from URL to clipboard (either practice or corporate group)
    async function handleCopyEntityId() {
        if (!practiceId && !corpGroupId) {
            showWarning('Failed to copy entity ID - no entity ID found in URL');
            return;
        }

        if (practiceId) {
            try {
                await navigator.clipboard.writeText(practiceId);

                showSuccessMessage('Copied practice entity ID to clipboard');
            } catch (e) {
                showWarning(`Failed to copy practice entity ID to clipboard: ${e}`);
            }
        } else if (corpGroupId) {
            try {
                await navigator.clipboard.writeText(corpGroupId);

                showSuccessMessage('Copied corporate group entity ID to clipboard');
            } catch (e) {
                showWarning(`Failed to copy corporate group entity ID to clipboard: ${e}`);
            }
        }
    }

    function handleToggleDesktopSidenav() {
        setDesktopOpened((prev) => !prev);
    }

    // Show a yellow warning toast
    function showWarning(message: string) {
        notifications.show({
            message,
            autoClose: 5000,
            withCloseButton: true,
            color: 'yellow',
            withBorder: true,
        });
    }

    // Show a green success toast
    function showSuccessMessage(message: string) {
        notifications.show({
            message,
            autoClose: 5000,
            withCloseButton: true,
            color: 'green',
            withBorder: true,
        });
    }

    return <>{children}</>;
}
