import { Box, Button, Flex, Image, Text, Title } from '@mantine/core';
import * as Sentry from '@sentry/react';
import { IconHome } from '@tabler/icons-react';
import Head from 'next/head';
import React, { Component, ErrorInfo, ReactNode } from 'react';

interface Props {
    children: ReactNode;
}

interface State {
    hasError: boolean;
}

// https://react.dev/reference/react/Component#catching-rendering-errors-with-an-error-boundary
// https://stackoverflow.com/questions/63916900/how-to-properly-type-a-react-errorboundary-class-component-in-typescript
// Our goal is to explicitly catch errors throughout the app and handle them gracefully as close to the error as possible
// This ErrorBoundary is a fallback for safety that allows us to report any unhandled errors
// If we do get any unhandled errors, we should fix them ASAP, so ultimately users shouldn't ever land here
class ErrorBoundary extends Component<Props, State> {
    public state: State = {
        hasError: false,
    };

    public static getDerivedStateFromError(_: Error): State {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // Alert ourselves
        Sentry.captureException(error, { extra: { errorInfo } });
    }

    public render() {
        if (this.state.hasError) {
            return (
                <>
                    <Head>
                        <title>dvmGateway Portal - Unexpected error</title>
                        <meta property="og:title" content="dvmGateway Portal" key="title" />

                        {/* TODO: dvmReach favicon for now, maybe change in the future */}
                        <link rel="icon" type="image/x-icon" href="/favicon-reach.ico" />
                    </Head>

                    <main>
                        <Flex
                            direction="column"
                            align="center"
                            justify="center"
                            w="100vw"
                            h="100vh"
                            gap="md"
                        >
                            {/* Logo */}
                            <Image src="/gateway-logo.png" alt="dvmGateway logo" w="12rem" />

                            {/* Header */}
                            <Title order={2} mt="xs">
                                Uh oh! Something unexpected went wrong.
                            </Title>

                            {/* Contact us if no account yet */}
                            <Box style={{ textAlign: 'center' }}>
                                <Text>Sorry about that.</Text>

                                <Text>
                                    The dvmReach team has been alerted, so you can expect the issue
                                    to be fixed soon.
                                </Text>

                                <Text>
                                    In the meantime, try reloading the portal, or contact us if you
                                    need additional support.
                                </Text>

                                <Button
                                    mt="lg"
                                    color="black"
                                    leftSection={<IconHome size="1rem" />}
                                    component="a"
                                    href="/"
                                >
                                    Reload
                                </Button>
                            </Box>
                        </Flex>
                    </main>
                </>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
