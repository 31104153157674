import { MantineBreakpoint } from '@mantine/core';

// Breakpoint for determining when to show mobile vs desktop components
export const DESKTOP_BREAKPOINT_WIDE: MantineBreakpoint = 'lg';

export const DESKTOP_BREAKPOINT: MantineBreakpoint = 'sm';
export const DESKTOP_BREAKPOINT_NUMBER = 768;

export const TABLET_BREAKPOINT: MantineBreakpoint = 'xs';

// Keys to use when using the `useLocalStorage` hook
// Anyone can use these same keys to read from any component, without worrying about global state or context
export enum LOCAL_STORAGE_KEYS {
    ADMIN_PRACTICE_FILTER = 'admin-practice-filter',
    ADMIN_PHARMACY_PARTNER_FILTER = 'admin-pharmacy-partner-filter',
    ADMIN_TASK_TYPE_FILTER = 'admin-task-type-filter',

    // Most recently selected practice UUID, helpful for remembering the last practice the user was viewing
    LAST_SELECTED_PRACTICE_UUID = 'last-selected-practice-uuid',

    INBOX_SNAPSHOT = 'inbox-snapshot',

    // Prescriptions page status filters to apply across page transitions and refreshes
    PRESCRIPTIONS_DEBOUNCED_SEARCH = 'prescriptions-filter__debounced-search',
    PRESCRIPTIONS_STATUS_FILTER = 'prescriptions-filter__status',
    PRESCRIPTIONS_DATE_RANGE = 'prescriptions-filter__date-range',

    // Reports status filters
    REPORTS_DATE_RANGE = 'reports-filter__date-range',

    // Whether the sidenav should be open or closed
    SIDENAV_OPEN_STATE = 'sidenav-open-state',
}

// Some sensible defaults
export const MAX_BUTTON_WIDTH = '240px';

// Debounce time for search inputs
export const DEBOUNCE_TIME_MS = 500;

// For pagination throughout the app
export const DEFAULT_PAGE_SIZE = 20;
