import { useRouter } from 'next/router';

interface ExpectedQueryParams {
    practiceUuid: string;
    prescriptionUuid: string;
    taskUuid: string;
    cycle?: 'inbox' | 'prescriptions';
    dateRangeStart?: string;
    dateRangeEnd?: string;
    corpGroupId?: string;
    practiceId?: string;
    startMonth?: string;
    endMonth?: string;
    callId?: string;

    // For Reach call performance dashboard
    startDay?: string; // YYYY-MM-DD
    endDay?: string; // YYYY-MM-DD
}

// Helper hook to grab expected query params with some additional type safety
export function useQueryParams(): ExpectedQueryParams {
    const router = useRouter();
    const {
        practiceUuid,
        prescriptionUuid,
        taskUuid,
        cycle,
        dateRangeStart,
        dateRangeEnd,
        corpGroupId,
        practiceId,
        startMonth,
        endMonth,
        callId,
        startDay,
        endDay,
    } = router.query;

    const expectedQueryParams: ExpectedQueryParams = {
        practiceUuid: practiceUuid?.toString() ?? '',
        prescriptionUuid: prescriptionUuid?.toString() ?? '',
        taskUuid: taskUuid?.toString() ?? '',
        cycle:
            cycle?.toString() === 'inbox'
                ? 'inbox'
                : cycle?.toString() === 'prescriptions'
                  ? 'prescriptions'
                  : undefined,
        dateRangeStart: dateRangeStart ? decodeURIComponent(dateRangeStart.toString()) : '',
        dateRangeEnd: dateRangeEnd ? decodeURIComponent(dateRangeEnd.toString()) : '',
        corpGroupId: corpGroupId?.toString() ?? '',
        practiceId: practiceId?.toString() ?? '',
        startMonth: startMonth?.toString() ?? '',
        endMonth: endMonth?.toString() ?? '',
        callId: callId?.toString() ?? '',
        startDay: startDay?.toString() ?? '',
        endDay: endDay?.toString() ?? '',
    };

    return expectedQueryParams;
}
