import ErrorBoundary from '@/components/shared/errors/error-boundary';
import KeyboardShortcutProvider from '@/components/shared/keyboard-shortcut-provider';
import { useBaseUrl } from '@/hooks/shared/useBaseUrl';
import '@/styles/globals.css';
import { DATADOG_CONFIG_MAP, PortalEnv } from '@/utils/shared/datadogConfig';
import { Auth0Provider } from '@auth0/auth0-react';
import { datadogRum } from '@datadog/browser-rum';
import {
    DefaultMantineColor,
    MantineColorsTuple,
    MantineProvider,
    createTheme,
} from '@mantine/core';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/dropzone/styles.css';
import { Notifications } from '@mantine/notifications';
import '@mantine/notifications/styles.css';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { basicLogger, withLDProvider } from 'launchdarkly-react-client-sdk';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { ComponentType } from 'react';

// Initialize Datadog RUM
datadogRum.init(DATADOG_CONFIG_MAP[process.env.NEXT_PUBLIC_RX_ENV as PortalEnv]);

// Initialize Sentry
Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    integrations: [],
    // https://github.com/getsentry/sentry-javascript/issues/3440
    ignoreErrors: ['Non-Error promise rejection captured'],
});

// Initialize Mantine
// Global theme overrides and other global style things go here
// Generated here https://mantine.dev/colors-generator/?color=315AB2 using the old portal table header color
const dvmBlue: MantineColorsTuple = [
    '#ebf3ff',
    '#d9e2f8',
    '#b1c3ea',
    '#87a2dd',
    '#6385d2',
    '#4d74cb',
    '#406bc9',
    '#315ab2',
    '#2850a0',
    '#1a458f',
];

const rxPurple: MantineColorsTuple = [
    '#E9DAFB',
    '#E9DAFB',
    '#E9DAFB',
    '#802FEA',
    '#802FEA',
    '#802FEA',
    '#802FEA',
    '#400D82',
    '#400D82',
    '#400D82',
];

const theme = createTheme({
    // Add custom color and make it primary
    colors: {
        'dvm-blue': dvmBlue,
        'rx-purple': rxPurple,
    },
    primaryColor: 'rx-purple',
    // Make headings a bit less bold, and customize fonts
    fontFamily: 'Inter, sans-serif',
    headings: {
        fontWeight: '500',
        fontFamily: 'Inter, sans-serif',
    },
    // Default input cursor type
    cursorType: 'pointer',
});

// Custom types so we can get auto-complete for our new color
// https://mantine.dev/theming/colors/#add-custom-colors-types
type ExtendedCustomColors = 'dvm-blue' | 'rx-purple' | DefaultMantineColor;

declare module '@mantine/core' {
    export interface MantineThemeColorsOverride {
        colors: Record<ExtendedCustomColors, MantineColorsTuple>;
    }
}

// Initialize React Query
const queryClient = new QueryClient();

function App({ Component, pageProps }: AppProps) {
    const { baseUrl } = useBaseUrl();
    const router = useRouter();

    if (!baseUrl) return null;

    return (
        <Auth0Provider
            domain={`${process.env.NEXT_PUBLIC_AUTH0_DOMAIN}`}
            clientId={`${process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID}`}
            authorizationParams={{
                redirect_uri: `${baseUrl}/auth-callback`,
                audience: 'dvmreach/dashboard-api',
            }}
            cacheLocation="localstorage"
            useRefreshTokens={true}
            onRedirectCallback={(appState) => {
                // Redirect to the returnTo URL if it exists
                // This means user attempted to access a protected route while logged out and was prompted to login
                if (appState?.returnTo) {
                    router.push(appState.returnTo);
                }
            }}
        >
            <QueryClientProvider client={queryClient}>
                <MantineProvider theme={theme}>
                    <ErrorBoundary>
                        {/* Allows any component to easily show notifications */}
                        <Notifications position="top-right" />

                        <KeyboardShortcutProvider>
                            <Component {...pageProps} />
                        </KeyboardShortcutProvider>
                    </ErrorBoundary>
                </MantineProvider>
            </QueryClientProvider>
        </Auth0Provider>
    );
}

// LaunchDarkly requires us to use a higher-order component to wrap our app
const AppWithLD = withLDProvider({
    clientSideID: process.env.NEXT_PUBLIC_LD_ID ?? '',
    // At this point we don't have a user - we'll update this later after authentication
    context: {
        kind: 'user',
        anonymous: true,
    },
    options: {
        // Set the log level to warn so we don't get too much noise in the console
        logger: basicLogger({ level: 'warn' }),
    },
})(App as unknown as ComponentType<{}>);

export default AppWithLD;
